import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Divider, Icon } from 'semantic-ui-react'
import toast from 'react-hot-toast'
// Components
import {
  Primary as OrderButton,
  Loading,
  Seo,
  CartProductCard,
  OrderDetails,
  OrderConfirmation,
  OrderFailed,
  AddCoupon,
  PageHeader,
} from '../components'
// Styles
import { Wrapper, Container, StyledSpan } from '../styles/common'
// Context
import { useStore } from '../context/StoreContext'
import { useAuthContext } from '../context/AuthContext'
import { useCart } from '../context/CartContext'
// Utils
import {
  OUT_OF_STOCK,
  setBillingAndShippingAddressOnCart,
  setEventBillingAndShippingOnCart,
} from '../utils/cartHelpers'
import { usePrismic } from '../context/PrismicContext'
import analytics from '../utils/analytics'

const CartPage = () => {
  const {
    prismicData: { prismicCartPage },
  } = usePrismic()

  const {
    title,
    header,
    thanks_for_shopping_with,
    shipping_to,
    card_ending_in,
    shipping_address,
    add_shipping_address,
    payment_information,
    credit_debit_card,
    ending_in,
    add_card,
    empty_warning,
    keep_shopping,
    sign_in_error,
    shipping_address_error,
    which_address_should_we_use,
    please_add_shipping,
    please_add_a_payment_method,
    loading_cart,
    processing_order,
    place_order,
  } = prismicCartPage

  const [cartShippingAddress, setCartShippingAddress] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showOrderError, setShowOrderError] = useState(false)
  const [orderStatus, setOrderStatus] = useState(null)
  const [orderError, setOrderError] = useState(null)
  const [orderNumber, setOrderNumber] = useState(null)
  const [shippingAddressSet, setIsShippingAddressSet] = useState(false)
  const [shippingMethod, setShippingMethod] = useState(null)

  const { isEventSite, isAmbassador, isUserAuthenticated, qUser } =
    useAuthContext()
  const { userData, getSelectedCard } = useStore()
  const { cartData, manageCart, handleUpdateCartData, placingOrder } = useCart()

  const { lastFourDigits, creditCardType } = getSelectedCard()

  useEffect(() => {
    if (!isUserAuthenticated) {
      toast.error(sign_in_error[0].text)
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if (!cartData || !userData) return

    // if user has only 1 address on file, then set it as shipping address on cart
    if (cartData.shipping_addresses.length === 1) {
      setIsShippingAddressSet(true)
      setCartShippingAddress(cartData.shipping_addresses[0])
      return
    }

    // if no shipping address exists for cart, look for a default address and set it
    const findDefaultAddress = addresses =>
      addresses.find(address => address?.default_shipping)
    const defaultAddress = findDefaultAddress(userData.addresses)

    // if no default address exists, set the first address
    if (!defaultAddress) return

    setBillingAndShippingAddressOnCart(
      cartData.id,
      defaultAddress,
      handleUpdateCartData,
      null
    )
    setIsShippingAddressSet(true)
    setCartShippingAddress(defaultAddress)
  }, [cartData?.shipping_addresses, cartData?.id, userData])

  useEffect(() => {
    if (!cartData) return

    const freeShippingMethod =
      cartData?.shipping_addresses[0]?.available_shipping_methods?.find(
        method => method.carrier_code === 'freeshipping'
      )

    const method = freeShippingMethod
      ? freeShippingMethod
      : cartData?.shipping_addresses[0]?.available_shipping_methods[0]
    setShippingMethod(method)
  }, [cartData?.shipping_addresses])

  const handleOrderError = (error, status) => {
    setOrderError(error)
    setOrderStatus(status)
    setShowOrderError(true)
  }

  const handlePlaceOrder = async () => {
    if (!shippingAddressSet) {
      toast.error(shipping_address_error[0].text)
      return
    }

    window.scrollTo(0, 0) // scroll to top of page
    let { orderNumber, error, status } = await manageCart.placeOrder()
    setOrderNumber(orderNumber)
    if (error) handleOrderError(error, status)
    else {
      analytics('purchase')
      setShowConfirmation(true)
    }
  }

  const uplineUser = qUser?.uplineUser?.displayName ?? 'n/a'
  const ambassadorName = qUser?.displayName ?? ''

  const shippingTo = () => {
    let shippingText = which_address_should_we_use[0].text
    if (!shippingAddressSet && userData?.addresses.length < 1)
      shippingText = please_add_shipping[0].text
    if (shippingAddressSet) {
      const { firstname, lastname, street } = cartShippingAddress
      shippingText = `${firstname} ${lastname}, ${street[0]}`
    }
    return (
      <Link to="/shipping-info">
        <StyledSpan bold>{shippingText}</StyledSpan>
      </Link>
    )
  }

  const cardInfo = () => {
    let cardText = lastFourDigits
      ? lastFourDigits
      : please_add_a_payment_method[0].text
    return (
      <Link to="/payment-info">
        <StyledSpan bold>{cardText}</StyledSpan>
      </Link>
    )
  }

  const isAProductOutOfStock = cartData?.items?.some(
    ({ product: { stock_status } }) => stock_status === OUT_OF_STOCK
  )

  if (!cartData || !userData)
    return (
      <Loading
        loading={!cartData || !userData}
        message={loading_cart[0].text}
      />
    )

  if (placingOrder)
    return (
      <Loading
        loading={placingOrder}
        message={processing_order[0].text}
        showBg={isEventSite}
      />
    )

  // TODO - Make entire row of shipping address & payment info clickable
  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        {cartData?.items.length > 0 ? (
          <>
            <PageHeader exitRoute="/">{header[0].text}</PageHeader>
            <Container padded>
              {isAmbassador ? (
                <StyledSpan data-qa="ambName" isTitle>
                  {ambassadorName}
                </StyledSpan>
              ) : (
                <StyledSpan>
                  {thanks_for_shopping_with[0].text}{' '}
                  <StyledSpan data-qa="uplineName" isTitle>
                    {uplineUser}
                  </StyledSpan>
                </StyledSpan>
              )}
              <StyledSpan data-qa="shippintAddress">
                {shipping_to[0].text} {shippingTo()}
              </StyledSpan>
              <StyledSpan data-qa="cardInfo">
                {card_ending_in[0].text} {cardInfo()}
              </StyledSpan>
              {/* <span>
                Reward points available{' '}
                <span style={{ fontWeight: 'bolder' }}>18 </span>
                (redeem now)
              </span> */}
              <OrderDetails
                {...cartData['prices']}
                shipping={shippingMethod}
                item_count={cartData.total_quantity}
                total_savings={cartData.total_savings}
                applied_coupons={cartData.applied_coupons}
              />
            </Container>
            <Container padded>
              <AddCoupon />
            </Container>
            <Container padded>
              {cartData.items.map(item => (
                <div key={item.uid}>
                  <CartProductCard item={item} />
                </div>
              ))}
            </Container>
            <Container gray padded align="center">
              <h3>{shipping_address[0].text}</h3>
            </Container>
            <Container padded row justify="space-between" align="center">
              <Container>
                {cartShippingAddress ? (
                  <>
                    <h3 data-qa="shipToName">{`${cartShippingAddress.firstname} ${cartShippingAddress.lastname}`}</h3>
                    <span data-qa="shipToAddress">
                      {`${cartShippingAddress?.street[0]}`}
                      {cartShippingAddress?.street[1] &&
                        `, ${cartShippingAddress.street[1]}`}
                    </span>
                    <span data-qa="shipToAddress2">{`${cartShippingAddress.city}, ${cartShippingAddress.region.code}, ${cartShippingAddress.postcode}`}</span>
                  </>
                ) : (
                  <span>{add_shipping_address[0].text}</span>
                )}
              </Container>
              <Link data-qa="addShipping" to="/shipping-info">
                <Icon name="chevron right" size="big" />
              </Link>
            </Container>
            <Container gray padded align="center">
              <h3>{payment_information[0].text}</h3>
            </Container>
            <Container padded row justify="space-between" align="center">
              <Container margin="1em 0 0 0">
                <h3>{credit_debit_card[0].text}</h3>
                {lastFourDigits ? (
                  <span data-qa="addCard">
                    {creditCardType} {ending_in[0].text} {lastFourDigits}
                  </span>
                ) : (
                  <span>{add_card[0].text}</span>
                )}
              </Container>
              <Link data-qa="addCard" to="/payment-info">
                <Icon name="chevron right" size="big" />
              </Link>
            </Container>
            <Divider />
            <Container align="center">
              <OrderButton
                onClick={handlePlaceOrder}
                content={place_order[0].text}
                disabled={
                  cartData.shipping_addresses.length === 0 ||
                  !lastFourDigits ||
                  isAProductOutOfStock
                }
              />
            </Container>
          </>
        ) : (
          <Container padded align="center" justify="center" height="80vh">
            <h1>{empty_warning[0].text}</h1>
            {showConfirmation && orderNumber && (
              <OrderConfirmation
                orderNumber={orderNumber}
                email={cartData.email}
                show={setShowConfirmation}
                isEventSite={isEventSite}
              />
            )}
            {showOrderError && orderNumber && (
              <OrderFailed
                orderNumber={orderNumber}
                orderStatus={orderStatus}
                userName={qUser.displayName}
                show={setShowOrderError}
                errorData={orderError}
              />
            )}
            <OrderButton as={Link} to="/products">
              {keep_shopping[0].text}
            </OrderButton>
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default CartPage
